import { message } from "antd";
import axios from "axios";

/**
 * 대시보드 employDashCount
공고관리 employWorkCount
예약공고관리 employWorkReserveCount
근무중관리 employWorkingListCount
근로자관리 employWorkListCount
정산내역 employSettlementCount
현장파트너관리 employPartnerCount
 */
const ApiEndpoints = {
  DASHBOARD: {
    GRAPH: "dashboard/graph", // 대시보드 employDashCount
  },
  WORK: {
    BASE: "work", // 공고관리 employWorkCount
    RESERVE: "work/reserve", // 예약공고관리 employWorkReserveCount
    PARTNER: "work/partner/list", // 현장파트너관리 employPartnerCount
  },
  EXP: {
    BASE: "experience",
    WORKING: "experience/working/list", // 근무중관리 employWorkingListCount
    WORK: "experience/work/list", // 근로자관리 employWorkListCount
  },

  SETTLEMENT: {
    BASE: "settlement", // 정산내역 employSettlementCount
  },
};

// 각 엔드포인트에 대한 추적 데이터를 관리하는 객체
const ApiTrackingConfig = {
  [ApiEndpoints.DASHBOARD.GRAPH]: {
    trackingKey: "employDashCount",
    description: "대시보드 조회",
  },
  [ApiEndpoints.WORK.BASE]: {
    trackingKey: "employWorkCount",
    description: "공고관리 조회",
  },
  [ApiEndpoints.WORK.RESERVE]: {
    trackingKey: "employWorkReserveCount",
    description: "예약공고관리 조회",
  },
  [ApiEndpoints.WORK.PARTNER]: {
    trackingKey: "employPartnerCount",
    description: "현장파트너관리 조회",
  },
  [ApiEndpoints.EXP.WORKING]: {
    trackingKey: "employWorkingListCount",
    description: "근무중관리 조회",
  },
  [ApiEndpoints.EXP.WORK]: {
    trackingKey: "employWorkListCount",
    description: "근로자관리 조회",
  },

  [ApiEndpoints.SETTLEMENT.BASE]: {
    trackingKey: "employSettlementCount",
    description: "정산내역 조회",
  },
};

const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.timeout = 2500;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 2500,
});

// 대충 request.url 중에 user ,work 일때만 다시 서버 해당 api 조회 했다 이런식으로 api post 할거임.
// 그렇기 때문에 url을 관리할 상수 값을 관리하게 해줘
api.interceptors.request.use(
  async (request) => {
    const sessionData = sessionStorage.getItem("employmentAgencyId"); // 예시로 sessionStorage에서 세션 토큰을 가져옴

    if (sessionData) {
      // 세션이 있을 경우
      const requestUrl = request.url || "";
      // console.log("@@requestUrl", requestUrl);
      // 요청 URL이 설정된 API 트래킹에 포함되는지 확인
      // console.log("@@requestUrl:", requestUrl);
      const trackingConfig = Object.entries(ApiTrackingConfig).find(([url]) => {
        //console.log("@@url:", url);
        // if (requestUrl === url) {
        //   console.log("@@requestUrl true:", requestUrl);
        // }
        return requestUrl === url; // 정확히 일치하는 URL을 찾기 위해 반환
      });
      console.log("@@trackingConfig", trackingConfig);
      if (trackingConfig) {
        const [endpoint, config] = trackingConfig;

        // console.log("@@endpoint:", endpoint);
        // console.log("@@config:", config);
        // console.log("@@Request URL matches USER or WORK, performing extra request...");
        try {
          // API 경로, 파라미터, 헤더 등 모든 정보를 콘솔에 출력
          // 추가적으로 해당 api를 호출한 페이지 경로도 콘솔로 확인하고 싶음.
          // console.log("@@sessionData: ", sessionData);
          // console.log("@@API Request:");
          //console.log(`@@URL: ${request.url}`);
          // console.log(`@@Method: ${request.method}`);
          // console.log("@@Headers:", request.headers);
          // console.log("@@Page Pathname:", window.location.pathname); // 호출한 페이지의 경로
          // console.log(`@@Search Params:`, window.location.search); // 검색 파라미터 추가 출력
          const params = window.location.search;
          console.log(`@@params:`, params); // 검색 파라미터 추가 출력
          // if (request.params) {
          //   console.log("@@Params:", request.params);
          // }
          // if (request.data) {
          //   console.log("@@Data:", request.data);
          // }
          // 예시로 해당 API에 다시 조회 요청을 할 수 있음
          // console.log("@@Params:", request.params);
          if (params === "") {
            console.log("@@Tracking API", config.trackingKey);
            await api.post(`tracking`, { param: config.trackingKey });
          }
        } catch (error) {
          console.error("@@Error performing extra request:", error);
        }
      }
    }
    // 여기서 세션 체크 가능함?
    // 특정 URL일 때 다시 서버 조회 요청 수행 (예: /user 또는 /work)

    return request; // 요청을 그대로 진행
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response, // 성공 응답 처리
  (error) => {
    // 401 에러 감지
    // if (error.response && error.response.status === 401) {
    //   navigate("/authentication/sign-in/basic"); // 로그인 페이지로 리다이렉션
    // }

    if (error.response) {
      switch (error.response.status) {
        case 401:
          sessionStorage.clear();
          message.error("세션이 만료되었습니다. 다시 로그인해주시기바랍니다.");
          // window.location.href = "/authentication/sign-in/basic";
          window.location.replace("/authentication/sign-in/basic");
          // navigate("/authentication/sign-in/basic", { replace: true }); // 로그인 페이지로 리다이렉션
          break;
        case 498:
          sessionStorage.clear();
          break;

        case 477:
          message.error(`${error.response.data}`);
          break;
        case 500:
          // notification.error({
          //   message: "500 Internal Server Error - Something went wrong on server",
          //   description: error.response.data.message ?? "",
          //   placement: "topRight",
          // });
          message.error(`${error.response.data.message ?? ""}`);
          // 여기에 500 에러에 대한 추가적인 처리를 할 수 있습니다.
          break;
        default:
          message.error(`${error.response.data.message}`);
        // console.log(`Error ${error.response.status}: ${error.response.statusText}`);
        // 기타 오류에 대한 처리
      }
    } else {
      // 오류 응답이 없는 경우 (네트워크 오류 등)
      // notification.error({
      //   message: "An error occurred, but the server did not respond",
      //   description: error.response.data.message ?? "",
      //   placement: "topRight",
      // });
      message.error(`${error.response.data.message}`);
    }

    return Promise.reject(error);
  }
);

export { initAxios, api };
